import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "DATING SITE – FOR “BEAUTIFUL PEOPLE”",
  "author": "tzookb",
  "type": "post",
  "date": "2018-02-27T11:50:58.000Z",
  "url": "/2018/02/dating-site-for-beautiful-people/",
  "categories": ["Uncategorized"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`{.content-preview-title}`}</h1>
    <p><strong parentName="p">{`Client:`}</strong>{` Own Project`}</p>
    <p><strong parentName="p">{`Date:`}</strong>{` 2012`}</p>
    <p>{`I created this project for myself as a learning project to learn codeigniter. I needed to create something so I could really understand the framework and its ins and outs. The site idea was inspired from the notorious site `}{`“`}{`beautifulpeople.com`}{`”`}{`.`}</p>
    <p>{`Some technology stack of this project: php, mysql, javascript, html, css, jquery, build upon codeigniter.`}</p>
    <p>{`My project workflow:`}</p>
    <p>{`Summarised all the project pages, features and functionalities.`}</p>
    <p>{`Sketched the project pages skeleton for design`}</p>
    <p>{`Found a designer threw elance`}</p>
    <p>{`In the end, I bought a theme threw `}{`“`}{`template Monster`}{`”`}</p>
    <p>{`Start coding the UI of the project and pages`}</p>
    <p>{`Start building the backend and api`}{`’`}{`s`}</p>
    <p>{`Deployed project`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      